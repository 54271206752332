.user-info-item {
    display: flex;
    flex-direction: row;
    background-color: var(--secondary);
    padding: 7px;
    border-radius: 5px;
    margin: 7px;
    font-size: 1.3em;
}
.email-icon {
    border-radius: 50px;
    position: absolute;
    right: 30px;
    width: 26px;
    height: 26px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.role-label {
    padding: 5px 12px;
    background-color: var(--bg);
    color: black;
    width: 100px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.role-input-picker {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    flex-grow: 1 !important;
}
.password-error-msg-wrapper {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgb(220, 20, 60, 0.3);
    border-radius: 5px;
}
