.watcher-slider-container {
    flex-grow: 1;
    padding-left: 30px;
    padding-right: 20px;
    padding-top: 5px;
    height: max-content;
    align-self: center;
}
.watcher-slider-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}
.watcher-slider-time {
    width: 50px;
}
