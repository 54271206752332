.test-line {
    position: absolute;
    background-color: var(--primaryDark);
    width: 20px;
    height: 20px;
    transform: skewY(-45deg);
}
.system-line {
    margin-top: -15px;
    margin-left: -5px;
    background-color: var(--primary);
    z-index: 100;
}
.retraining-line {
    margin-top: -12px;
    margin-left: 0px;
    background-color: var(--primaryDark);
    z-index: 50;
}
.one {
    margin-top: 35px;
    margin-left: 0px;
    z-index: -1;
}
.two {
    margin-top: -9px;
    margin-left: 222px;
    z-index: -1;
}
.three {
    margin-top: 40px;
    margin-left: -60px;
    z-index: -1;
}
.four {
    margin-top: 36px;
    margin-left: 215px;
    z-index: 10;
    width: 20px;
    height: 15px;
    transform: skewY(-45deg);
    background-color: var(--bg);
}

.five {
    margin-top: 42px;
    margin-left: 215px;
    z-index: 1;
    width: 20px;
    height: 20px;
    transform: skewY(-45deg);
    background-color: var(--bg);
}
.back-one {
    margin-top: 29.5px;
    margin-left: -14px;
    z-index: -1;
}
.back-two {
    margin-top: -20px;
    margin-left: 35px;
    z-index: -1;
}
.back-three {
    margin-top: 42px;
    margin-left: 25px;
    z-index: 1;
    width: 20px;
    height: 20px;
    transform: skewY(-45deg);
    background-color: var(--bg);
}
.proc {
    margin-top: -14px;
    background-color: var(--primary);
    margin-left: -5px;
    z-index: -1;
}
.new {
    margin-top: 15.3px;
    transform: skewY(-40deg) !important;
    height: 35px !important;
    width: 35px !important;
    margin-left: -4px;
    z-index: -1 !important;
}
.breadline {
    margin-top: -51.5px;
    margin-left: 0px;
    z-index: 1;
    background-color: var(--primary);
}

.btn-line {
    margin-top: -14px;
    margin-left: -24px;
    z-index: 1;
    background-color: var(--primary);
}
