.app-container {
    display: flex;

    flex-direction: column;
    height: max-content;
    min-height: 100vh;
}
.app-wrapper {
    height: max-content;
    min-height: 100vh;
}
