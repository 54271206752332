.auth-container {
    background-color: var(--primary);
    padding: 5px 20px;
    border-radius: 10px;
    padding-bottom: 10px;
}
.auth-inputs {
    appearance: none;
    padding: 5px;
    font-size: 1.1em;
    border-radius: 5px;
    outline: none !important;
}
.login-page {
    width: max-content !important;
    margin-top: 30vh;
    margin-left: auto;
    margin-right: auto;
}
.auth-error-message {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid var(--red);
    margin-top: 7px;
    background-color: var(--primary);
    color: var(--red);
}
