.nav-list-container {
    display: flex;
    justify-content: center;
    background-color: var(--primary);
    margin: 0px !important;
    width: 200px;
}
.navlist-input {
    position: absolute;
    width: 200px;
    height: 50px;
    background-color: var(--primary);
}
.nav-list-container-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dropdown-toggle {
    background-image: linear-gradient(
        -45deg,
        transparent 8px,
        var(--primaryDark) 8px
    );

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 10px;
    padding: 7px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
.dropdown-toggle:hover {
    filter: brightness(1.3);
}
.dropdown-icon {
    margin-left: 5px;
}
