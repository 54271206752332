.header-content-wrapper > div > a {
    color: white !important;
}
.header-content-wrapper > a {
    color: white !important;
}
.header-content-wrapper {
    display: flex;
    padding: 5px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.header-content-wrapper > p {
    color: var(--text) !important;
}
.sticky-table-header {
    background-color: var(--primary);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: sticky !important;
    z-index: 100 !important;
}
.custom-header-row {
    background-color: var(--secondaryLight);
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.custom-header-sort-button > p {
    color: var(--textNegative);
    margin-right: 5px;
}

.custom-header-sort-button {
    display: flex !important;
    flex-direction: row !important;
    flex-grow: 1;
    color: var(--textNegative);
    align-items: center;
    justify-content: center;
}
.non-sortable-custom {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100px !important;
    align-items: center;
    color: var(--textNegative);
}

.custom-header-row > :first-child {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
}
