.flexbox-retraining {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.flexbox-retraining > div:first-child {
    flex-grow: 1;
    margin-right: 15px;
    min-width: 650px;
}

.flexbox-retraining > div:nth-child(2) {
    flex-grow: 1;
    margin-right: 15px;
    min-width: 460px;
}

.session-table {
    flex-grow: 1;
    margin-left: auto;
    margin-bottom: 20px;
}

.session-table-list {
    flex-grow: 1;
    margin-left: auto;
}

.checkbox-header {
    width: min-content !important;
    height: min-content !important;
}

.checkbox-container {
    height: min-content !important;
    width: 100px;
    margin-left: auto !important;
}

.table-addition {
    align-items: center;
    width: 100%;
    height: 45px;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    margin-bottom: 5px;
    padding-right: 5px;
}
.command-filter-container {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    padding: 5px;
}
.table-header {
    justify-content: flex-start;
    margin-bottom: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.table-header > p {
    margin-left: 5px;
}
.table-footer {
    margin-top: 5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.session-name-input {
    width: 150px !important;
    border-radius: 0px !important;
}
.retraining-row {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
}

.session-cell {
    cursor: text !important;
}
.close-added-session-btn {
    position: absolute !important;
    right: 30px;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
}
.custom-cell {
    background-color: var(--secondary) !important;
}
.session-command-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 350px;
    height: 35px;
    overflow: hidden;
}
.session-btn {
    margin-left: 10px;
    border-radius: 0px !important;
}

.input-picker-menu {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    background-color: var(--primary) !important;
}

.empty-table-holder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkbox-div {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
}
.table-row {
    padding: 0px !important;
    cursor: pointer !important;
}
.selectable-column {
    cursor: text !important;
}
.checkbox-div > .table-cell-wrapper > a {
    color: white !important;
}

.data-session-name-cell-content {
    margin: 0px 5px !important;
}
.data-session-name-cell-content > p {
    max-width: 100%;
    overflow-x: hidden;
}
