.header-container {
    position: fixed;
    width: 100%;
    z-index: 1050;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    height: 45px;
}
.logout-button {
    height: 100% !important;
    border-radius: 0px !important;
    background-color: var(--secondaryLight) !important;
}
.logout-button:hover {
    filter: brightness(1.2);
}
.fixed-header {
    position: fixed;
    height: 45px;
    z-index: 1;
}
.logout-icon {
    background-color: var(--secondaryLight) !important;
    filter: brightness(0.9);
}
.header-rem {
    margin-left: 40px !important;
    color: var(--secondaryLight) !important;
    filter: brightness(0.8) !important;
}
.header-brain {
    color: var(--secondaryLight) !important;
    filter: brightness(1.2) !important;
}
.header-img-logo {
    z-index: 1000 !important;
}
.header-spacer {
    height: 45px !important;
}
