.watcher-modal {
    position: absolute;
    top: 15%;
    left: 35%;
    width: 30%;
    height: 55%;
    background-color: var(--primary);
    border: 0px solid black;
    border-radius: 5px;
    outline: none;
}

.watcher-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.watcher-settings-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.watcher-settings-separator {
    width: 95%;
    height: 1px;
    margin-top: 5px;
    background-color: var(--gray);
}
.watcher-speed-container {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.watcher-speed-option {
    font: bold;
    padding: 10px 15px;
    transition: ease-out 0.4s;
    text-decoration: none !important;
    width: 60px;
    text-align: center;
}
.watcher-speed-option:hover {
    text-decoration: underline;
}
.watcher-active-speed {
    background-color: var(--secondaryLight);
    color: var(--textNegative);
    border-radius: 5px;
}
.watcher-setting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
