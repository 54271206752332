.switcher-btn {
    font-size: 1.3em !important;
    border-radius: 0px !important;
    background-color: var(--secondaryLight) !important;
}

.switcher-disabled-btn {
    font-size: 1.3em !important;
    border-radius: 0px !important;
    background-color: var(--secondaryLight) !important;
    filter: brightness(0.8);
}
.switcher-btn:hover {
    filter: brightness(1.2);
    background-color: var(--secondaryLight) !important;
}
.switcher-btn:nth-child(2) {
    background-image: linear-gradient(
        45deg,
        transparent 7px,
        var(--secondaryLight) 7px
    ) !important;
    background-color: transparent !important;
}

.switcher-btn:nth-child(2):hover {
    background-image: linear-gradient(
        45deg,
        transparent 7px,
        var(--secondaryLight) 7px
    ) !important;
    filter: brightness(1.2);
    background-color: transparent !important;
}

.switcher-disabled-btn:nth-child(2) {
    background-image: linear-gradient(
        45deg,
        transparent 7px,
        var(--secondaryLight) 7px
    ) !important;
    background-color: transparent !important;
}

.switcher-disabled-btn:nth-child(2):hover {
    background-image: linear-gradient(
        45deg,
        transparent 7px,
        var(--secondaryLight) 7px
    ) !important;
    background-color: transparent !important;
}

.switcher-wrapper {
    margin-left: auto;
    width: max-content;
    background-color: transparent;
    background-image: linear-gradient(
        45deg,
        transparent 8px,
        var(--primaryDark) 8px
    ) !important;
    padding: 3px;
    padding-bottom: 5px;
    padding-right: 7px;
}
.switcher-wrapper > .switcher-btn {
    margin-right: 3px;
}

.switcher-wrapper > .switcher-btn:last-child {
    margin-right: 0px;
}
.switcher-line {
    position: absolute !important;
    background-color: var(--primaryDark);
    width: 20px;
    height: 20px;
    transform: skewY(45deg);
}
.switcher-line:nth-child(1) {
    margin-top: -13px;
    margin-left: -23px;
    z-index: -1;
}
.switcher-line:nth-child(2) {
    border-top: 4px solid var(--primaryDark);
    margin-top: 37px;
    margin-left: -7px;
    z-index: 10;
    background-color: var(--bg) !important;
}
.switcher-block {
    position: absolute !important;
    background-color: var(--bg);
    z-index: 20;
}
.switcher-block:nth-child(3) {
    width: 10px;
    height: 20px;
    margin-top: 20px;
    margin-left: -13px;
}
.switcher-block:nth-child(4) {
    width: 20px;
    height: 10px;
    margin-top: 45px;
    margin-left: 0px;
}
.switcher-container-wrapper {
    position: fixed !important;
    right: 0px !important;
    z-index: 100 !important;
}
