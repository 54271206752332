.retraining-step-wrapper {
    display: flex;
    flex-direction: row;
    width: max-content;
    justify-content: center;
}

.table-tabs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table-tab {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin: 0px !important;
    padding-bottom: 15px !important;
}

.table-tab:last-child {
    margin-left: 5px !important;
}

.add-session-filter-wrapper {
    background-color: var(--secondary);
    padding: 4px;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin-right: 10px;
}
.add-sessions-modal-body {
    height: max-content !important;
    min-height: 60vh !important;
}
.retraining-session-input-group {
    margin: 15px !important;
    width: 25em !important;
}
.retraining-session-input-addon {
    width: 8em !important;
}
