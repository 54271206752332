.watcher-main-layout-container {
    width: 100%;
    height: 100%;
}
.watcher-main-layout-left {
    width: 45vw;

    float: left;
    position: fixed;
    top: 110px;
    bottom: 65px;
    left: 260px;
    overflow-y: hidden;
}
.watcher-main-layout-left:hover {
    overflow-y: scroll !important;
}
.watcher-main-layout-right {
    float: right;
    margin-top: 2vh;
    flex-grow: 1;
    height: 100%;
}
.watchtower-wrapper-container {
    display: flex;
    flex-direction: row;
}
.watchtower-main-spacer {
    width: 47vw;
    height: 20px;
}
.watcher-fixed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.watcher-left-wrapper {
    padding-bottom: 150px;
}
.watcher-container {
    background-color: var(--primaryDark);
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border-radius: var(--borderRadius);
}

.watcher-mobile-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding-top: 10px;
    width: 65vw;
}
html {
    scroll-behavior: smooth;
}
input,
select,
textarea {
    color: var(--text);
}
textarea,
input {
    outline: none;
}
:root {
    --green: #7fab59;
    --red: #bf616a;

    --text: var(--text);
    --gray: #d8dee9;
    --lightgray: var(--secondaryLight);
    --borderRadius: 5px;
    --primaryBorder: 3px;
}
.watcher-spacer {
    flex-grow: 1;
}
.wt1 {
    position: fixed;
    margin-top: -15px;
    margin-left: -2px;
    z-index: -1;
}

.inverted-line {
    transform: skewY(45deg);
    position: fixed;
    background-color: var(--primary);
    width: 20px;
    height: 20px;
}

.wt2 {
    margin-top: -15px;
    right: 0;
    z-index: -1;
    position: fixed;
}
.wt3 {
    margin-top: 85vh;
    margin-left: -2px;
    z-index: -1;
    position: fixed;
}

.wt4 {
    margin-top: 85vh;
    margin-left: 98.93vw;
    z-index: -1;
    position: fixed;
}
