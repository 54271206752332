.tag-picker-item {
    color: var(--text) !important;
    transition: ease-out 0.4s;
}

.data-table-tag-picker {
    width: 28vw !important;
}

.data-session-loader {
    z-index: 100 !important;
}
