.watcher-player-container {
    aspect-ratio: 16/9;
    max-width: 920px;
}
.watcher-player-image {
    width: 100%;
    height: 100%;
}
.watcher-debug-info {
    position: absolute;
    padding: 10px;
    color: var(--textNegative);
    background-color: rgba(20, 20, 20, 0.5);
}
.download-frame-btn {
    position: absolute;
    padding: 15px;
    color: var(--textNegative);
    background-color: rgba(20, 20, 20, 0.5);
    border-bottom-left-radius: 10px;
}
.download-frame-btn:hover {
    background-color: rgba(50, 50, 50, 0.5);
}
