.task-list-wrapper {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 15px !important;
}
.tasks-item-wrapper {
    background-color: var(--primary);
    border-radius: 5px;
    width: min-content !important;
}
.focused-item {
    background-color: var(--scroll);
    outline: 2px solid var(--scroll);
}
.task-item-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1.3em;
    background-color: var(--secondaryLight);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    align-items: baseline;
    padding: 4px 8px;
}
.task-item-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    font-size: 1.3em;
    background-color: var(--secondaryLight);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: baseline;
    padding: 4px 8px;
}
.task-item-header > p {
    color: var(--textNegative);
    height: min-content;
}
.task-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.task-controls {
    z-index: 1000 !important;
    background-color: var(--primaryDark);
    border: 2px solid var(--primary);
    position: sticky;
    top: 41px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 95%;
    margin-top: 10px;
}
.create-task-btn {
    height: max-content;
    align-self: center;
    color: var(--textNegative) !important;
}
.task-sort-btn {
    height: max-content;
    align-self: center;
    color: var(--textNegative) !important;
    border-radius: 5px;
    background-color: var(--secondaryLight);
    padding: 7px;
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
    transition: ease-out 0.4s;
    margin: 5px;
}
.task-sort-btn:hover {
    filter: brightness(1.2);
}
.task-sort-btn > p {
    color: var(--textNegative) !important;
}
.task-footer-btn {
    margin: 0px 5px;
    filter: brightness(1.2);
}

.task-controls > :last-child {
    margin-right: 10px;
}
