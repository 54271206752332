.json-stage-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.json-btn {
    position: absolute !important;
    z-index: 1 !important;
    right: 15px;
    top: 0px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    margin: 0px !important;
}

.nn-weights-container {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.nn-weights-item {
    padding: 6px;
    background-color: var(--secondary);
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
}
.nn-weights-item:hover {
    filter: brightness(0.8);
}
.retraining-config-modal {
    width: 65% !important;
}
.retraining-config-textarea {
    height: 40vh !important;
}
.retraining-weights-header {
    margin-top: 10px !important;
}
.dag-input-full {
    margin-top: 5px !important;
    width: 300px !important;
}
.dag-input-half {
    margin-top: 5px !important;
    width: 150px !important;
}
.dag-link {
    color: white !important;
    margin-left: 10px;
}

.airflow-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--secondary);
    border-radius: 5px;
    padding: 4px 7px;
}
.airflow-input-addon {
    width: 6.5em !important;
}
.rs-btn-toggle-checked {
    background-color: var(--secondaryLight) !important;
}
