.sidenav-sidebar {
    background-color: var(--primary);
    height: 100%;
    min-height: max-content;
    margin-right: 0px !important;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    width: max-content !important;
    box-shadow: 1.5px 0px 0px var(--primary);
}
.sidenav-dropdown {
    padding: 5px;
    background-image: linear-gradient(
        -45deg,
        transparent 10px,
        var(--primaryDark) 10px
    );
    width: 230px !important;
}
.sidenav-group-dropdown {
    background-color: var(--primary);
}

.nav-item-label {
    text-align: end;
    color: var(--text);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.sidenav-toggle {
    transition: ease-out 0.4s;
    color: var(--text) !important;
}

.active-toggle {
    background-color: var(--secondaryLight) !important;
    filter: brightness(1.1);
    color: var(--textNegative) !important;
}

.navlist-dropdown {
    margin-top: 50px;
    max-height: 30vh !important;
    overflow-y: scroll;
    width: 200px;
    transition: ease-out 0.4s;
}
.sidenav-toggle:hover {
    background-color: var(--secondaryLight) !important;
    color: var(--textNegative) !important;
}
.nav-item {
    background-color: var(--primary) !important;
    transition: ease-out 0.4s;
}

.nav-item:hover {
    filter: brightness(1.1);
}
.nav-item .nav-item-label {
    transition: ease-out 0.4s;
}
.nav-item:hover .nav-item-label {
    color: var(--textNegative) !important;
}
.active-item {
    background-color: var(--secondary) !important;
    filter: brightness(0.94);
    transition: ease-out 0.4s;
}

.nav-item p {
    animation: none !important;
}
.nav-item a:focus p {
    color: white;
}
.sidenav-body {
    max-height: 80vh;
    padding-bottom: 40px;
    transition: ease-out 0.4s;
    overflow-y: scroll !important;
}
.sidenav-group-dropdown .nav-item-label {
    padding-left: 30px !important;
}
.sidenav-sidebar > :first-child {
    width: 245px !important;
}
