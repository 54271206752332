.watcher-side-bar {
    background-color: var(--primary);
    border-top: var(--primaryBorder) solid var(--secondary);
    position: fixed;
    float: right;
    width: 100%;
    padding-right: 40px;
    bottom: 0;
    left: 0;
    padding-left: 240px;
    height: 60px;
    display: flex;
    flex-direction: row;
    z-index: 9;
}
.watcher-sidebar-btn-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}
.watcher-left-sidebar-btn-container {
    width: 200px;
    border-right: var(--primaryBorder) solid var(--primary);
}
.watcher-right-sidebar-btn-container {
    width: max-content;
    border-left: var(--primaryBorder) solid var(--primary);
}
.watcher-side-bar-mobile {
    background-color: var(--primary);
    border-top: var(--primaryBorder) solid var(--secondary);
    position: fixed;
    float: right;
    width: 100%;
    bottom: 0;
    padding-left: 245px;
    left: 0;
    height: 100px;
    display: flex;
    flex-direction: column;
    z-index: 9;
}
.watcher-mobile-bar-controls {
    margin-bottom: 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}
.watcher-control-container-item {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    border-top: var(--primaryBorder) solid var(--secondary);
}
.main-control {
    flex-grow: 2 !important;
    border-left: var(--primaryBorder) solid var(--secondary);
    border-right: var(--primaryBorder) solid var(--secondary);
}
