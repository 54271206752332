.stream-download-btn {
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 4px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(60, 60, 60, 0.5);
}
.stream-download-btn:hover {
    background-color: rgba(80, 80, 80, 0.6) !important;
}
.breadcrumb-row {
    background-color: var(--primaryDark);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: min-content;
    align-content: center;
    margin-left: -4px !important;
}
.breadcrumb-item {
    font-size: 1.5em !important;
    color: var(--secondaryLight) !important;
}
.breadcrumb-active {
    font-size: 1.5em !important;
    color: var(--text) !important;
}
.breadcrumb {
    margin: 0px !important;
}
.robot-details-container {
    height: min-content;
}

.robot-stream-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    height: min-content !important;
}

.robot-stream-header > h2 {
    color: var(--text) !important;
}

img[src=''] {
    display: none;
}
.debug-operator-info-container > span:first-child {
    width: 75px;
}
.responsive-stream-container {
    width: 100%;
}
.robot-pannel {
    border-radius: 5px !important ;
    background-color: var(--primaryDark) !important;
    margin-bottom: 10px;
}
.robot-camera-input {
    max-width: 100px !important;
}
.robot-push-button {
    margin: 0px 5px 0px 0px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.robot-config-wrapper {
    border-radius: 5px !important ;
    padding-bottom: 10px;
    background-color: var(--primaryDark);
    height: min-content !important;
}
.robot-config-wrapper > h5 {
    padding: 7px !important;
}

.robot-to-vr-btn:first-of-type {
    margin-left: 8px;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.robot-to-vr-btn:nth-last-of-type(1) {
    margin-left: 2px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.stream-download-btn .rs-icon {
    color: var(--negativeText) !important;
}
