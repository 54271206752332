.audio-container {
    display: flex;
    flex-direction: row;
    background-color: rgba(50, 50, 50, 0.5);
    padding: 10px;
    z-index: 10;
    width: max-content;
    border-radius: 5px;
    position: absolute;
}
.audio-btn {
    background-color: rgba(100, 100, 100, 0.8) !important;
}
