.wo-item-name {
    position: absolute;
    z-index: 10;
    font-size: 1.2em;
    background-color: rgba(50, 50, 50, 0.8);
    padding: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 200px;
    text-align: center;
}
.wo-backdrop {
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.wo-operator-btn {
    padding: 15px;
    border-radius: 50px;
    background-color: rgba(50, 50, 50, 0.92);
}
.wo-operator-btn:hover {
    filter: brightness(1.2);
}
.wo-btn-container > a {
    color: white !important;
}

@keyframes box-alert {
    0% {
        box-shadow: inset 0px 0px 40px 10px rgba(220, 20, 60, 0.7);
    }
    50% {
        box-shadow: inset 0px 0px 40px 30px rgba(220, 20, 60, 0.7);
    }
    100% {
        box-shadow: inset 0px 0px 40px 10px rgba(220, 20, 60, 0.7);
    }
}

.attention-robot-div {
    position: absolute;
    z-index: 5;
    animation-name: box-alert;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
}
.web-operator-tooltip {
    background-color: rgba(50, 50, 50, 0.92) !important;
    font-size: 1em !important;
}

.operator-btn {
    background-color: var(--primary);
    font-size: 4em;
    margin: 10px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
}
.operator-btn:hover {
    filter: brightness(0.9);
}
.empty-list-text {
    background-color: rgba(50, 50, 50, 0.9);
    padding: 8px;
    font-size: 1.2em;
    border-radius: 5px;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
}

.empty-list-text > p {
    color: var(--textNegative) !important;
}
.wo-btn-container {
    margin: 5px;
}

.wo-pannel-loader-wrapper {
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
