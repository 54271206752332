.json-input-textarea {
    overflow: hidden !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    resize: none !important;
}
.json-input-container {
    max-width: 100%;
    overflow-y: scroll;
}
.json-editor-error {
    position: absolute;
    bottom: 1.5em !important;
    left: 77%;
    z-index: 100;
    border-radius: 7px;
    background-color: rgba(100, 100, 100, 0.75);
    padding: 7px;
    color: var(--textNegative);
    transform: translate(-50%, -50%);
    width: 40%;
    bottom: 14%;
}
.CodeMirror {
    height: 100% !important;
    width: 100% !important;
}
.code-editor {
    font-size: 0.85em;
    height: 100%;
}
