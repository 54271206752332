.watcher-event-item {
    width: 95%;
    padding: 0px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.watcher-event-item-header {
    padding: 5px;
    padding-left: 15px;
    border-top-left-radius: var(--borderRadius);
    border-top-right-radius: var(--borderRadius);
    color: var(--textNegative);
    filter: brightness(0.9);
}
.watcher-event-item-body {
    width: 100% !important;
    background-color: var(--primaryDark) !important;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.watcher-event-item-active {
    background-color: var(--green);
}
.watcher-event-item-error {
    background-color: var(--red);
}
.watcher-event-item-inactive {
    background-color: var(--lightgray);
}
.watcher-event-item-footer {
    padding: 5px;
    padding-left: 15px;
    margin-bottom: 20px;
    height: 20px;
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
}
.watcher-item-group-body {
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: var(--primaryDark);
}
.watcher-item-group-body > div {
    width: min-content !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin: 0px;
    border-radius: var(--borderRadius);
}
.watcher-item-group-body > div:last-child {
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.watcher-item-group-body > div:first-child {
    margin-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.active-header:hover {
    filter: brightness(0.8);
}
.watchtower-command-textarea {
    height: fit-content;
}
.separator {
    background-color: var(--scroll);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    filter: opacity(0.3) brightness(0.9);
    height: 2px;
}
:last-child > .separator {
    background-color: transparent !important;
    height: 0px !important;
}
