.email-error-input {
    background-color: var(--red) !important;
    filter: brightness(1.5);
}
.email-error-addon {
    background-color: var(--red) !important;
    filter: brightness(2);
    color: black;
    color: black !important;
    width: 100px !important;
}
.create-user-input-group {
    margin: 15px 0px !important;
}
.create-user-input-group > :first-child {
    width: 100px !important;
}
