.box-marker-stream {
    width: fit-content;
    padding-bottom: 10px !important;
    padding-top: 30px;
}
.marker-button {
    margin: 10px;
}
.marker-bottom {
    display: flex;
    flex-grow: 1;
    align-items: center !important;
    justify-content: flex-start;
    flex-direction: row;
}
.marker-res_output {
    background-color: var(--primary);
    color: var(--text);
    border-radius: 5px;
    flex-grow: 1;
    height: 2.5em;
    margin-top: auto;
    padding: 5px;
}
.marker-legend {
    margin-top: 20px;
    color: var(--text);
}
.user-bin-inputs {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    color: var(--text);
    margin-right: 10px;
    margin-top: auto;
}
.box-marker-stream-container {
    color: var(--primary);
}
.box-marker-stream-container > div > p {
    color: var(--text) !important;
}
.marker-toggle-container {
    display: flex;
    margin-top: auto;
    padding: 0.4em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondaryLight);
    border-radius: 5px;
}
.marker-toggle-container > p {
    margin-right: 10px;
    color: var(--primary) !important;
}
.flex-icon-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
}
.marker-toggle-active {
    background-color: var(--secondaryLight) !important;
    filter: brightness(0.8);
}
.marker-message-input {
    color: var(--text) !important;
    background-color: var(--primary) !important;
}
