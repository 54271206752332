.operator-top-row {
    display: flex;
    flex-direction: row;
    position: fixed !important;
    top: 45 !important;
}

.web-operator-item {
    background-color: var(--secondary);
    overflow: hidden;
    flex-grow: 1;
    min-width: 500px !important;
    max-width: 1000px !important;
    margin: 10px;
    aspect-ratio: 16/9;
}
.web-operator-container {
    color: var(--textNegative);
    width: 100%;

    display: flex;
    padding: 30px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.stream-container {
    width: max-content;
    min-height: max-content;
    height: max-content;
    background-color: var(--primaryDark);
}
.operator-back-btn-cut {
    padding: 5px 15px;
    background-color: transparent;
    background-image: linear-gradient(
        -45deg,
        transparent 8px,
        var(--primaryDark) 8px
    ) !important;
}
.operator-back-btn {
    padding: 5px 15px;
    background-color: var(--primaryDark);
}

.operator-back-btn-cut:hover,
.operator-back-btn:hover .operator-back-icon {
    color: var(--scroll);
}

.debug-operator-container button {
    background-color: var(--secondary) !important;
    color: var(--text) !important;
}
.debug-operator-container button:disabled {
    filter: brightness(0.6);
}
.debug-operator-container button:hover {
    filter: brightness(0.9);
}
.debug-operator-container {
    color: var(--text) !important;
    padding-bottom: 20px;
}

.debug-command-container {
    background-color: var(--primary);
    color: var(--text) !important;
}
.command-item {
    color: var(--text) !important;
    background-color: var(--secondary);
}
.command-item-container {
    color: var(--text) !important;
    margin-top: 10px;
}
.calibration button {
    color: var(--text) !important;
    margin-right: 5px;
}

.no-available-robot {
    color: var(--text) !important;
    margin-top: 60px !important;
}
