.notifications-modal-btn-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 10px;
}
.file-view-modal {
    width: 900px !important;
    overflow: hidden !important;
}
.file-view-modal-body {
    height: 550px !important;
    width: 870px !important;
    overflow-y: scroll !important;
}
.notifications-modal-btn-container > :nth-child(2) {
    margin-left: 10px;
}
