body {
    margin: 0;
    height: 100% !important;
}
html {
    scroll-behavior: smooth;
    scrollbar-color: var(--scroll) var(--primary);
}
p {
    color: var(--text);
}
a {
    color: black !important;
}
input,
select,
textarea {
    color: black !important;
}
button {
    background-color: dimgrey;
}
button:disabled {
    background-color: gray;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-corner {
    background-color: var(--primary) !important;
}
.file-view-modal-body::-webkit-scrollbar,
html::-webkit-scrollbar,
.CodeMirror-vscrollbar::-webkit-scrollbar {
    width: 7px !important;
    background-color: var(--primary) !important;
    height: 10px !important;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-track {
    background-color: var(--primary) !important;
}
.CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
    background-color: var(--secondary) !important;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondaryLight) !important;
}
.file-view-modal-body::-webkit-scrollbar-corner,
html::-webkit-scrollbar-corner,
.json-input-textarea::-webkit-scrollbar-corner,
.CodeMirror-vscrollbar::-webkit-scrollbar-corner,
.custom-scrollbar::-webkit-scrollbar-corner {
    background-color: var(--primary);
}

.json-input-textarea::-webkit-scrollbar,
.CodeMirror-vscrollbar::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
    width: 7px;
    background-color: var(--primary);
    height: 10px;
}
html {
    overflow-y: scroll !important;
}
.file-view-modal-body::-webkit-scrollbar,
html::-webkit-scrollbar,
.json-input-textarea::-webkit-scrollbar,
.CodeMirror-vscrollbar::-webkit-scrollbar {
    height: 0px !important;
    scrollbar-gutter: stable both-edges !important;
}
.file-view-modal-body html::-webkit-scrollbar-track,
.json-input-textarea::-webkit-scrollbar-track,
.CodeMirror-vscrollbar::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--primary);
}
.file-view-modal-body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb,
.json-input-textarea::-webkit-scrollbar-thumb,
.CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scroll);
}
.file-view-modal-body::-webkit-scrollbar-thumb:hover,
html::-webkit-scrollbar-thumb:hover,
.json-input-textarea::-webkit-scrollbar-thumb:hover,
.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover,
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondaryLight);
}
span > span::before {
    background-color: var(--secondaryLight) !important;
}

.arm-state-green {
    color: var(--green) !important;
}
.arm-state-red {
    color: var(--red) !important;
}
.app-body {
    padding-right: 0px !important;
}
