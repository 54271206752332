.task-runner-task-item {
    padding: 5px;
    background-color: var(--primaryDark) !important;
    border-radius: 5px;
    transition: ease-in 0.3s;
    color: var(--text);
    cursor: pointer !important;
}
.task-runner-task-item:hover {
    -webkit-box-shadow: inset 0px 0px 0px 1.5px var(--secondaryLight);
    -moz-box-shadow: inset 0px 0px 0px 1.5px var(--secondaryLight);
    box-shadow: inset 0px 0px 0px 1.5px var(--secondaryLight);
}
.task-runner-task-item > p,
.task-runner-task-item > h5 {
    width: 100%;
    word-wrap: break-word !important;
}
.task-runner-task-item > h5 {
    color: var(--secondaryLight);
    filter: brightness(0.8);
}

.backdrop-modal-test {
    background-color: crimson !important;
    width: 200vw !important;
}
