.main-layout-row {
    padding: 10px;
    margin-top: 20px;
}
.non-selectable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.table-cell-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.breadcrumb-spacer-wrapper {
    height: 40px;
}

.breadcrumb-fixed-wrapper {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.not-allowed-wrapper {
    background-color: var(--primary);
    width: max-content;
    border-radius: 5px;
    margin-top: 20vh;
    padding: 15px !important;
}

.custom-scrollbar {
    scrollbar-gutter: stable;
}
.custom-scrollbar::-webkit-scrollbar-corner {
    background-color: var(--primary);
}
.custom-scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: var(--primary);
    height: 7px;
}
.custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--primary);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scroll) !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondaryLight) !important;
}

.list-filter-input {
    width: 200px !important;
    margin: 5px !important;
}

.delete-whisper-btn {
    margin: 7px !important;
}

.on-empty {
    color: var(--text) !important;
}
.spacer {
    flex-grow: 1;
}
.fixed {
    position: fixed !important;
}
.full-width {
    width: 100% !important;
}
.margin-center-h {
    margin-left: auto !important;
    margin-right: auto;
}
.w-max-content {
    width: max-content !important;
}
.d-flex {
    display: flex;
}
.negative-text {
    color: var(--textNegative) !important;
}
.text {
    color: var(--text) !important;
}
.no-text-decor {
    text-decoration: none !important;
}
