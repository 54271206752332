.watcher-sensor-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    background-color: crimson !important;
}
.watcher-sensor-item {
    margin: 5px;
    height: min-content;
    background-color: var(--secondary);
    padding: 5px;
    border-radius: var(--borderRadius);
}
.watcher-sensor-subcontainer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--primaryDark);
}
.head-item {
    font-size: 1.4rem;
    text-align: left;
}
