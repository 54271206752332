.notifications-robot-container {
    width: 100%;
    margin-top: 10px;
    box-shadow: inset 0px 0px 0px 3px var(--secondary);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
    margin-bottom: 10px;
}
.notification-robot-item {
    transition: ease-out 0.4s;
    padding: 8px;
    margin: 5px;
    min-width: max-content;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    max-width: 100px !important;
    height: max-content !important;
    background-color: var(--secondary);
    border-radius: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.notification-robot-item:hover {
    filter: brightness(0.9);
    transition: ease-out 0.4s;
}

.selected-robot-all-item {
    background-color: var(--green);
    color: var(--textNegative) !important;
}

.selected-robot-watch-item {
    background-color: var(--red);
    color: var(--textNegative) !important;
}
.notification-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.robot-notifications-p {
    text-align: center !important;
    margin-top: 5 !important;
    font-size: 0.9em !important;
}
